.breadcrumb-wrapper {
  margin-top: $navbar-height;
  //background-color: $breadcrumb-bgcolor;
  border-bottom-style: solid;
  border-block-color: $breadcrumb-border;
  border-block-width: 0.01rem;
  font-size: 0.8125rem;
  padding-top: 8px;
  padding-bottom: 8px;
}

.breadcrumb-links {
  color: $enerdis-blue;
  font-size: 0.8125rem;
  text-decoration: none;
}

.breadcrumb-links:hover {
  color: $enerdis-blue;
  text-decoration: underline;
  cursor: pointer;
}

.breadcrumb-label {
  color: $breadcrumb-label_color;
}

.breadcrumb-separator {
  color: $breadcrumb-label_color;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
