* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.main-view-wrapper {
  margin-top: 2rem;
  margin-bottom: 6rem;
}

.gray-section {
  background-color: $gray-section_bgcolor;
}

.peak-color {
  color: $peak-color;
}

.base-color {
  color: $base-color;
}

.margin-top-1rem {
  margin-top: 1rem;
}

.margin-top-2rem {
  margin-top: 2rem;
}
