.login-wrapper {
  width: 20%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10rem;
  @media only screen and (max-width: 600px) {
    width: 80%;
    margin-top: 5rem;
  }
}

.login-logo {
  width: 160px;
  height: auto;
}

.login-button {
  width: 100%;
}
