.text {
  color: $content-color;
  font-size: 0.9rem;
  line-height: 1.125rem;
}

.text-newsletter {
  color: $content-color;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: justify;
  margin-top: 0.725rem;
}

.text-newsletter_subtitle {
  color: $content-color;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: justify;
  margin-top: 1.125rem;
  text-decoration: underline;
}

.list-newsletter {
  margin-left: 3rem;
}

.inputlabel {
  color: $content-color;
  font-size: 0.9rem;
  line-height: 2.125rem;
}

a {
  color: $enerdis-blue;
  font-size: 0.9rem;
  line-height: 1.125rem;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.text-small {
  color: $content-color;
  font-size: 0.8rem;
  line-height: 1rem;
}

.text-sublabel {
  color: $subtitle-color;
  font-size: 0.725rem;
  line-height: 0.825rem;
}

.title0 {
  color: $content-color;
  font-size: 1.625rem;
  line-height: 1.625rem;
  font-weight: 700;
}

.title1 {
  color: $content-color;
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-weight: 700;
}

.title2 {
  color: $content-color;
  font-size: 0.9125rem;
  line-height: 1rem;
  font-weight: 700;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.text-svg-small {
  color: $content-color;
  font-size: 0.765rem;
  line-height: 0.825rem;
}
