// colors
$enerdis-orange: rgba(255, 206, 0, 1);
$enerdis-green: rgba(17, 170, 0, 1);
$enerdis-blue: rgba(0, 91, 151, 1);

$loader-color: rgb(43, 134, 32);

$base-color: rgba(95, 99, 173, 1);
$peak-color: rgba(54, 190, 187, 1);

$content-color: rgba(70, 70, 70, 0.8);
$title-color: rgba(53, 53, 53, 1);
$subtitle-color: rgba(117, 117, 117, 0.8);
$light-gray: #fafafa;
$blockSeparator-bg: rgba(239, 239, 239, 1);
$services-box-border-color: rgba(53, 53, 53, 0.2);

// Dimensions
$navbar-height: 76px;

// Breadcrumb
$breadcrumb-bgcolor: rgba(239, 239, 239, 0.6);
$breadcrumb-border: rgba(70, 70, 70, 0.2);
$breadcrumb-label_color: rgba(70, 70, 70, 0.8);

// gray background section
$gray-section_bgcolor: rgba(239, 239, 239, 0.6);
